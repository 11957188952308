import React from 'react'

import Button from '@/components/base/button/Button'
import Icon from '@/components/base/icon/Icon'
import { useApp } from '@/core/contexts/app'

const ScrollDown = () => {
  const app = useApp()
  const handleScrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }

  return (
    <Button onClick={handleScrollDown} className="scroll-down">
      <Icon name="keyboard-arrow-down" />
      <span>{app.settings.translations['scrolldown']}</span>
    </Button>
  )
}

export default ScrollDown
