import React, { useEffect, useRef, useState } from 'react'

import { classnames } from 'begonya/functions'

import { path } from '@/core/utils'
import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image/Image'
import Video from '@/components/base/video'

import ScrollDown from '../scroll-down/ScrollDown'

import { BannerProps } from './type'

const Banner: React.FunctionComponent<BannerProps> = ({
  title,
  desc,
  image,
  video,
  id,
}) => {
  const app = useApp()
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (video && videoRef.current) {
      videoRef.current.src = path.asset(app.environment, video.src)
      videoRef.current.play()
      videoRef.current.addEventListener('playing', () => {
        setLoaded(true)
      })
    } else {
      setLoaded(false)
    }
  }, [])

  const videoSettings = {
    autoPlay: true,
    controls: false,
    loop: true,
    muted: true,
    playsInline: true,
    preload: 'none',
  }

  return (
    <section className="banner" id={id}>
      <div className="banner-body">
        <div className={classnames('banner-body-image', loaded && 'hidden')}>
          <Image {...image} alt="Test" />
        </div>
        <div className="banner-body-video">
          {video && <Video ref={videoRef} {...videoSettings} />}
        </div>
        <div className="banner-body-text">
          <Container size="extended">
            <Row>
              <Column className="desc" xs={{ size: 4 }}>
                {title && <div className="title">{title}</div>}
                {desc && <div dangerouslySetInnerHTML={{ __html: desc }} />}
              </Column>
              <ScrollDown />
            </Row>
          </Container>
        </div>
      </div>
    </section>
  )
}

export default Banner
